.button-wrapper {
    position: relative;
    width: 150px;
    text-align: center;
    
  }
  
  .button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: #03aec0;
    cursor: pointer;
    color: #fff;
    padding: 10px 0;
    text-transform:uppercase;
    font-size:12px;
    border-radius: 10px;
  }
  
  #upload {
      display: inline-block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 50px;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
  }
  .btn-save{
    background: #b82d63;
    color: #fff;
    border-radius: 10px;
    width: 155px;
    cursor: pointer;
}

.avatar-img{
  width: 255px;
  height: auto;
}