.event {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 261px;
  gap: 55px;
}

.event__title {
  font-size: 45px;
  font-weight: 600;
  margin-top: 31px;
  color: #B82D63;

  @media (max-width: 640px) {
    font-size: 33px;
  }
}

.event__form {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  max-width: 800px;
  padding: 20px;
}

.event__suppliers {
  width: 100%;
}

.event__suppliers p {
  font-size: 13px;
  margin-bottom: 0;
}

.event__suppliers span {
  font-size: 11px;
  color: #c6c6c6;
}

.supplier__cards {
  width: 100%;
  height: 550px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  padding: 10px;
  margin-top: 13px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  overflow: auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 492px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.supplier__cards::-webkit-scrollbar {
  width: 10px;
}

.supplier__cards::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

.supplier__cards::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.60)
}

.supplier__card {
  display: flex;
  justify-content: center;
}

.supplier__empty-cards {
  width: 100%;
  height: 400px;
  padding: 10px;
  margin-top: 13px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  display: flex;
  justify-content: center;
}

.supplier__empty-cards p {
  margin-top: 25px;
}

.event__form-footer {
  display: flex;
  justify-content: center;
}

.event__form-footer button {
  padding: 10px 30px;
  border-radius: 30px;
  outline: none;
  border: none;
  background-color: #1EBCCC;
  color: #fff;
  font-weight: 600;
}

.event__form-footer img {
  position: absolute;
  left: 0;
  margin-top: -180px;
  z-index: -1;

  @media (max-width: 1024px) {
    display: none;
  }
}

