.btnModal{
    background: #b82d63;
    color: #fff;
    border-radius: 10px;
    width: 155px;
    border:1px solid #b82d63;
}

.btnModal:hover{
    background: #fff;
    color:#b82d63;
    border:solid 1px ;
    border-color: #03aec0;
}