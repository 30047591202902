footer{
    padding-top: 30px;
    margin-bottom: 0;
    bottom: 0;
    padding-bottom: 10px;
}
.footer-container{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;

}
.footer-description{
    width: 50%;
    padding: 0 2rem;
    color:#707072;
}
.footer-description h1{
    margin-bottom: 20px;
    font-size: 1.7rem;
    color: #707072;
}
.footer-description p{
    font-size: 1.2rem;
}
.footer-list{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.footer-list ul{
    margin-right: 50px;
    list-style: none;
}
.footer-list ul li{
    margin-bottom: 10px;
    font-size: 1.2rem;
}


.footer-list ul li a{
    color:#707072 ;
}
.cartoes{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 2rem;
}
.reclame-aqui-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
  }
  
  .reclame-aqui-link {
    display: inline-block;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .reclame-aqui-img {
    max-width: 230px; /* Tamanho máximo da imagem */
    border-radius: 8px; /* Bordas arredondadas */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  }
  
  .reclame-aqui-link:hover {
    transform: scale(1.05); /* Leve zoom ao passar o mouse */
  }
  .payment-icons {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    gap: 15px; /* Espaçamento entre os ícones */
    margin-top: 10px; /* Espaço acima dos ícones */
  }
  
  .payment-icons .fa-icon {
    font-size: 38px; /* Tamanho dos ícones */
    color: #b82d63; /* Cor do Lup (rosa mais escuro) */
  }
  

@media only screen and (max-width: 1080px) {
    .footer-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .footer-description{
        width: 100%;
        padding: 0 2rem;
    }
    .footer-list{
        justify-content: space-between;
        padding: 0 2rem;
    }
    .footer-list ul{
        margin-right: 0px;
        margin-top: 30px;

    }

}
@media only screen and (max-width: 600px) {
    .footer-list{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
    }
    .footer-list ul li{
        text-align: center;
    }
    .cartoes img{
        width: 100%;
    }
    .footer-description p{
        text-align: justify;
    }

}
