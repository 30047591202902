.promocoes h1{
    color:#03aec0;
    margin-bottom: 15px;
    font-size: 25px;
}
.promocoes h4{
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 25px;
}
.promocoes p{
    margin-bottom: 15px;
    font-size: 25px;
}
.nenhum-item{
    color:#03aec0;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
    
}

.nenhum-item h1{
    font-size: 25px;
}