
.info .info-event{
    padding: 15px;
    margin-bottom: 25px;
    border: 2px solid #03aec0;
    border-radius: 15px;
    position: relative;
 
}

.info .info-event p{
    font-weight: 700;
}


.info .info-event.button-link{
    background:  #03aec0;
    text-align: center;
    
}

.info-event.button-link button{
    color: #fff;
    padding: 0;
}

.info-event.button-link button:focus {
    box-shadow: none;
}


.info-icons{

    color: #03aec0;
    font-size: 25px;
    margin-left: -10px;
    position: absolute;
    right: 5px;
    top:5px;
    

}

.recados{
    display: flex;
    align-items: center;
}

.recados h2{
    font-size: 40px;
    font-weight: 500;
}

.recados .enviar-recado{
    width: 180px;
    height: 35px;
    background-color: #b82d63;
    color: #fff;
    font-size: 1rem;
    border: #b82d63;
    border-radius: 10px;
    margin-left: 50px;
    margin-top: -12px;
}

.lista-recados{
  
}

.lista-recados ul li{
    display: flex;
    list-style: none;
    align-items: center;
}

.lista-recados ul li svg{
    margin-right: 10px;
    font-size: 10px;
    color: #c2c2c2;
}

