.modal-title{
    text-align: center;
}

.modal-sub-title{
    font-size: 20px;
}

.modal-header{
    background: #be376a;
    color: #fff;
}


.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover{
    opacity: 1;
    color: #fff;
}

.btn-modal{
    background: #be376a;
    color: #fff;
    min-width: 100px;
}

.btn-modal:hover{
    background: #fff;
    border:1px solid #be376a;
    color: #be376a;
}