.btn-default{
    background: #b82d63;
    color: #fff;
    border-radius: 10px;
    width: 155px;
    cursor: pointer;
}

.btn-default:hover{
    color: #b82d63;
    background: #fff;
    border:1px solid #b82d63;
}

.purple-courtain{
    background: #a54170b7;
    padding: 30px;
    color:#fff;
}


.purple-courtain a {
    color: #fff;
}


.blue-courtain{
    background: #16acbd9d;
    padding: 30px;
    color:#fff;
}

.green-courtain{
    background: #8db817b7;
    padding: 30px;
    color:#fff;
    
}
