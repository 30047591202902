.container-filtro{
    padding: 2rem;
}
.title-filtro h1{
    text-align: center;
    font-size: 3.5rem;
    font-weight: 700;
    color: #323232;
} 
.title-filtro h2{
    text-align: center;
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.5rem;
}
.body-filtro{
    display:flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 30px;
}
.form-filter{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 50px;
}
.type-filter{
    width: 25%;
    margin-top:50px;
}
.type-filter ul{
    list-style: none;
}
.type-filter ul li{
    padding: 1.5rem 0rem;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #03aec052;
    font-size: 0.9rem;
    transition:  0.5s;
}

.type-filter ul li:hover{
    background-color: #b82d63;
    color:#fff;
}
.type-select-filter{
    background-color: #b82d63;
    color: #fff;
    border-bottom: none !important;
}

.filter-select{
    cursor: pointer;
}

.img-filter{
    /* margin-left: -120px; */
}
.form-filter{
    width: 20%;

}
.form-filter select{
    width: 100%;
    padding: 15px 0px;
    margin-bottom: 15px;
    font-size: 1.2rem;
    width: 100%;
    padding: 10px;
}
.form-filter button{
    padding: 15px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #03aec0;
    border: none;
}
.img-filer{
    width: 60%;
}
.img-filter img{
    width: 100%;
}
@media only screen and (max-width: 1000px) {

    .body-filtro {
        flex-direction: column;
        align-items: center;
    }
    .type-filter {
        width: 100%;
    }
    .form-filter {
        width: 100%;
    }
    .img-filter {
        margin-left: -50px;
        margin-top: 30px;
    }
}