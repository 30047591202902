.table th{
    background: #a5416f;
    color: #fff;
    border: none;
    text-align: center;
    padding: 4px 10px;
}

.obs{
    text-align: right;
}

th.num {
    position: relative;
    border: rgba(255, 255, 255, 0.5) solid thin;
}

.mensalidades .vertical {
    display: block;
    transform: rotate(-90deg);
    margin-top: 150px;
}

th.num:before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #a5416f transparent transparent transparent;
}