.slide{
    height: 500px;
    position: relative;
}
.slide-container{
    background-color: #ccc;
    height: 100%;
    overflow: hidden;
}
.slide-track{
    list-style: none;
    position: relative;
    height: 100%;
    transition: transform 250ms ease-in;
}

.slide-img{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    object-fit: cover;
}
.slide-img img{
    max-width: 100%;
    max-height: 100%;
}
.btn-slide-left{
    position: absolute;
    top: 50%;
    background-color: transparent;    
    border:none;
    z-index: 2;
}
.btn-slide-right{
    position: absolute;
    right: 0;
    top:50%;
    background-color: transparent;    
    border:none;
}
.thumb{
    display: flex;
    width: 100%;
}
.thumb li{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 130px;
    background-color: #ccc;
    border: 1px solid #000;
    margin-left: 10px;
    margin-top: 10px;
}
.thumb li:first-child{
    margin-left: 0;
}
.thumb li img{
    width: 100%;
    object-fit: contain;
    max-height: 100%;
    
}

@media only screen and (max-width: 800px) {
    .thumb li{
        height: auto;
       
    }
}