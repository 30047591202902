.galeria{
    width: 100%;
}
.galeria-body{
    display: grid;
     grid-template-columns: repeat(3, 1fr) ;
     color: #fff;
     width: 100% ;
}
.galeria-body div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    font-size: 1.5rem;
    white-space: nowrap;
    font-weight: 400;
}
.galeria-body .buffet{
    background-image: url('../../../Assets/buffet.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.galeria-body .casamento{
    background-image: url('../../../Assets/casamento.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.galeria-body .restaurante{
    background-image: url('../../../Assets/restaurante.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.galeria-body .foto{
    background-image: url('../../../Assets/foto.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.galeria-body .decoracao{
    background-image: url('../../../Assets/decoracao.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.galeria-body .vestuario{
    background-image: url('../../../Assets/vestuario.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.galeria-posts{
    display: flex;
    padding: 3rem;
    justify-content: center;
    
    
}
.post{
    display: flex;
    margin-left: 50px;
    margin-bottom: 30px;
}
.post-big{
    /* padding-right: 80px !important; */
    /* padding-left: 20px !important; */
    border-right: 2px solid #ccc;
}
.post-big div img{
    width: 100%;
}
.post-big h1{
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 10px;
    color:#545454;
}
.post-big h2{
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 20px;
    color:#545454;
}

.loading-row{
    position: relative;
}

.galeria-posts a{
    color: palevioletred;
}

.post-body{
    /* padding-left: 30px !important; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.post div{
    margin-left: 20px;
    
}
.post img{
    height: 150px;
    max-width: 210px;
}

.galeria-body a:hover{
    text-decoration: none;
}

.galeria-body a div h1{
    color: #fff;
}
.galeria-body a div h1:hover{
    color:#b82d63 ;

    
}
@media only screen and (max-width: 1100px) {
    .galeria-body{
        display: grid;
         grid-template-columns: repeat(2, 2fr) ;
         color: #fff;
         width: 100% ;
    }
    .galeria-posts {
        flex-direction: column;
    }
    .post-big{
        padding-right: 0px;
        padding-left: 0px;
        border-right:none;
        width: 100%;
    }
    .post-body {
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;
    }
    .post {
        display: flex;
        margin-left: 0px;
        margin-bottom: 30px;
    }
    
}
@media only screen and (max-width: 600px) {
    .galeria-body{
        display: grid;
         grid-template-columns: repeat(1, 2fr) ;
         color: #fff;
         width: 100% ;
    }
    .post {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-bottom: 50px;
        align-items: center;
    }
    .post div {
        margin-left: 0px;
        margin-top: 20px;
    }
    .post div p{
        text-align: justify;
    }    
    .post-body{
        margin-top: 50px;
    }
    
}