.container {
  padding: 16px;
  margin: 0 auto; 
}

.eventos-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  width: 100%;
}

.eventos-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.eventos-header h2 {
  font-size: 24px;
  margin: 0;
}

.btn-cadastrar-evento {
  background-color: #08acbc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
}

.btn-cadastrar-evento:hover {
  background-color: #08acbc;
}

.grid-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Ampliado para desktop */
  gap: 16px;
}

.evento-card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.evento-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.btn-visualizar-evento {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
}

.btn-visualizar-evento:hover {
  background-color: #0056b3;
}
.btn-visualizar-evento {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
}

.btn-visualizar-evento:hover {
  background-color: #0056b3;
}
