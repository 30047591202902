.container {
  padding: 16px;
  margin: 0 auto;
  max-width: 1200px; 
}

.detalhes-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.back-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.back-button:hover {
  background-color: #0056b3;
}

.orcamentos-detalhes h2,
.ramo-section h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.orcamento-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.orcamento-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.orcamento-header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orcamento-header p {
  font-size: 16px;
  color: #333;
  margin: 0;
}

.detalhes-button-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Espaço entre o botão e a linha */
  margin-top: 10px;
}

.detalhes-button {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none; /* Remove sublinhado */
  padding: 0;
}

.detalhes-button:hover {
  text-decoration: underline;
}

.detalhes-linha {
  flex-grow: 1; /* Faz a linha se expandir */
  height: 1px;
  background-color: #333;
}



.status-pendente,
.status-aprovado,
.status-reprovado {
  font-weight: bold;
  text-transform: uppercase;
  color: #333;
}

.orcamento-detalhes {
  margin-top: 15px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.6;
}

.itens-orcamento {
  margin-top: 20px;
}

.itens-tabela {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.itens-tabela th,
.itens-tabela td {
  border: none; /* Remove as linhas da tabela */
  text-align: left;
  padding: 8px 0;
}

.itens-tabela th {
  font-weight: bold;
  color: #333;
}

.total-label {
  text-align: right;
  font-weight: bold;
}

.total-valor {
  font-weight: bold;
  color: #333;
}

.contact-button {
  background-color: #25d366;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
}

.contact-button:hover {
  background-color: #1ebe5d;
}

.orcamento-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-right: 10px;
}

.no-items-message {
  font-style: italic;
  color: #666;
  margin-top: 10px;
}

/* Botões de aceitar e recusar com ícones */
.accept-button,
.reject-button {
  background: none;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  transition: transform 0.2s, background-color 0.2s;
}

.accept-button {
  background-color: #e6e6e6;
}

.accept-button:hover {
  background-color: #d4d4d4;
  transform: scale(1.1);
}

.reject-button {
  background-color: #e6e6e6;
}

.reject-button:hover {
  background-color: #d4d4d4;
  transform: scale(1.1);
}

.accept-button::before {
  content: "✔";
}

.reject-button::before {
  content: "✖";
}
.action-buttons{
  display: flex;
  flex-direction: row;
}
.orcamentos-titulo{
  color: #b82d63;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.popup-content h2 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.popup-content p {
  font-size: 16px;
  margin: 10px 0;
  color: #555;
}

.popup-close-button {
  background-color: #dc3545;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.popup-close-button:hover {
  background-color: #c82333;
}
