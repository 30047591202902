.img-fornecedor{
    width: 40px;
    height: 40px;
}
.modal-Fonrnecedor .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px 1rem;
}
.modal-Fonrnecedor .fornecedor-zero{
    padding: 15px;
    font-size: 25px;
}

.modal-Fonrnecedor table{
    margin-bottom: 50px;
}


@media (min-width: 576px){
.modal-Fonrnecedor .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
}
 }