@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}
p{
    margin-bottom: 0;
}

body, input, button{
    font-family: 'Roboto', sans-serif;
}


.mt-15{
    margin-top: 15px;
}
.mt-30{
    margin-top: 30px;
}

.mt-60{
    margin-top: 60px;
}

.mt-120{
    margin-top: 120px;
}

.mb-60{
    margin-bottom: 60px;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #000000;
    outline: 0;
    box-shadow: none;
}

.modal{
    z-index: 9999;
}


.alert-modal{
    font-size: 30px;
    background: #be376a;
    padding: 15px;
    color: #fff;
}

.btn-whats{
    background:#01e675;
    color: #fff;
}

.privacy-policy {
    padding: 8rem
}

@media (max-width: 798px) {
    
.privacy-policy {
    padding: 2rem
}
}