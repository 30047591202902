.fornecedores-container{
    display: flex;
    flex-grow: 2;
    padding: 5rem 3rem;
}
.sidebar{
    width: 100%;
    height: 100%;
    background: #ffffff;
    min-height: 600px;
    padding: 30px 20px;
    box-shadow: 0px 0px 5px 0px;
    max-width: 270px;
}
.sidebar > div{
    display: flex;
    flex-direction: column;
}
.sidebar h1{
    font-size: 1.3rem;   

}
.form-sidebar select{
    padding: 10px;
    margin: 10px 0px;
}
.main-fornecedores{
    margin-left: 50px;
    width: 100%;
}

.card-fornecedores{
    box-shadow: 0px 0px 1px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 350px;
    max-width: 500px;    
}
.img-card-fornecedores{
    width: 100px;
    height: 100px;
    margin-top: 40px;    
}
.img-card-fornecedores img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.bg-card-fornecedores{
    background-color: #be376a;
    width: 100%;
    display: flex;
    justify-content: center;
    height:  100px;
}
.body-card-fornecedores{
    margin-top: 40px;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 210px;
    width: 100%;
}
.body-card-fornecedores h1{
    color: #0eb1c2;
    font-size: 1.5rem;
}
.link-card-fornecedores{
    width: 100%;
    text-align: right;
    
    
}

.link-card-fornecedores a{
    color: #be376a;
}
.main-fornecedores > div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px
}
.main-fornecedores > h1{
    font-size: 1.5rem;
    margin-bottom: 15px;
}
.form-sidebar > button{
    padding: 10px;
    background-color: #0eb1c2;
    color: #fff;
    border: none;
    margin-top: 20px;
}

@media only screen and (max-width: 1080px) {
    .fornecedores-container{
        display: flex;
        flex-grow: 2;
        padding: 3rem 2rem;
        flex-direction: column;
    }
    .sidebar > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .sidebar {
        width: 100%;
        height: 100%;
        background: #ffffff;
        min-height: 100%;
        padding:0;
        box-shadow: none;
        max-width: 100%;
    }
    .main-fornecedores {
        margin-left: 0px;
        margin-top: 30px;
    }
}
@media only screen and (max-width: 800px) {
    .sidebar > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .form-sidebar select, .form-sidebar button {
        width: 100%;
    }
    .main-fornecedores > div{
        display: grid;
        grid-template-columns: 1fr 1fr ;
        gap: 30px
    }

}
@media only screen and (max-width: 550px) {

    .main-fornecedores > div{
        display: grid;
        grid-template-columns: 1fr ;
        gap: 30px
    }

}