.convite{
    display: flex;    
    
}

.convite button{
    background: #b82d63;
    padding: 5px;
    color: #fff;
    border-radius: 10px;
    width: 155px;
    cursor: pointer;
    margin-left: 10px;
}

.convite-nao{
    font-size: 20px; 
    color: #b82d63 ;
}

.convite-sim{
    font-size: 18px; 
}

.btn-evniar{
    margin-top: 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #03aec0;
    border: none;
}