.login-block label {
  font-size: 18px;
  color: #be376a;
  font-weight: 600;
}

.login-block input {
  background: #fff;
  border-radius: 15px;
  padding-left: 30px;
}

.btn.btn-login {
  width: 150px;
  height: 35px;
  background-color: #b82d63;
  color: #fff;
  font-size: 1rem;
  border: #b82d63;
  border-radius: 10px;
}

.login-block .user {
  position: relative;
}

.login-block .user .user-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #b82d63;
}

.login__title {
  color: #B82D63;
}

.custom-link {
  background: inherit;
  border: inherit;
  color: #B82D63;
  cursor: pointer;
}

.custom-link:hover {
  color: #B82D63;
  text-decoration: underline;
}