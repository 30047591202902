.dashboard-header-container {
  background-color: white; /* Fundo branco */
  padding: 30px 20px; /* Aumenta o espaço vertical */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box-shadow para separação */
  border-bottom: 1px solid #ddd; /* Linha sutil para separação */
  position: sticky; /* Header fixo no topo */
  top: 0;
  z-index: 1000; /* Garantir que fique acima do conteúdo */
}

.dashboard-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-logo {
  height: 90px; /* Aumenta a altura da logo */
  margin-left: 140px; /* Espaço entre a logo e o menu */
  object-fit: contain; /* Garante que a proporção da imagem seja mantida */
}

.dashboard-header-content nav {
  display: flex;
  align-items: center;
  gap: 20px; /* Espaço entre os itens do menu */
}

.dashboard-header-content nav a {
  color: #333; /* Cor do texto do link */
  text-decoration: none; /* Remove sublinhado */
  font-size: 16px; /* Texto levemente maior */
  font-weight: 500;
  transition: color 0.3s ease;
}

.dashboard-header-content nav a:hover {
  color: #08acbc; /* Cor ao passar o mouse */
  text-decoration: underline; /* Sublinhado ao passar o mouse */
}

.dashboard-header-content nav a.active {
  color: #08acbc; /* Cor do link ativo */
  font-weight: bold; /* Peso diferenciado para o link ativo */
}

.logout-button {
  background-color: #ff4d4d; /* Fundo vermelho */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-button:hover {
  background-color: #e60000; /* Fundo ao passar o mouse */
  transform: translateY(-2px); /* Animação ao passar o mouse */
}


@media (max-width: 500px) {
  .dashboard-logo {
    height: 70px; /* Aumenta a altura da logo */
    margin-left: 10px; /* Espaço entre a logo e o menu */
  }
}
