.banner{
    background-image: url('../../../Assets/banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding:7rem 5rem;
}
.banner-container{
    color: #ffffff;
    width: 70%;

}
.banner-container h1{
    font-size: 3.5rem;
    text-transform: uppercase;
    text-shadow: 4px 2px 4px #191919;
    margin-top: 3rem;
}
.banner-container H2{
    font-size: 1.7rem;
    margin-top: 20px;
    text-shadow: 4px 2px 4px #191919;
}
.banner-container button{
    padding: 1.5rem 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    background-color: #b82d63;
    color: white;
    border: none;
    margin-top: 70px;
    text-transform: uppercase;
}

@media only screen and (max-width: 1080px) {

    .banner{
        padding:3rem 2rem;
    }
    .banner-container{
        width: 100%;
    }
    .banner-container h1{
        font-size: 2.7rem;
    }
}