.container-tabs .MuiTabs-flexContainer {
    display: flex;
    background-color: #be376a;
    color: #fff !important;
}

.container-tabs .MuiTab-textColorPrimary.Mui-selected {
    background-color: #ce5c87;
    color: #fff;
    border: none;
}
.container-tabs .MuiTab-textColorPrimary {
    color: #fff;
}
.container-tabs .MuiAppBar-colorDefault {
    color: rgba(0, 0, 0, 0.87);
    background-color: #be376a !important;
}
.container-tabs .MuiSvgIcon-fontSizeSmall {
    color: #fff;
}
