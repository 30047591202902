

#os-links-section {
    margin-top: 24px;
    display: flex;
    justify-content: space-around;
    gap: 8rem;
}

@media (max-width: 798px) {
       
#os-links-section {
    flex-direction: column;
    gap: 3rem;
} 
}