
.carousel-indicators li {   
    opacity: 1;
    width: 12px;   
    height: 12px;   
    border-radius: 50%;
    background:#c2c2c2;
    box-sizing: border-box;
    border:none;
}

.carousel-indicators li.active{

    background:#be376a;

} 

.carousel-indicators{
    position:relative;
    margin-top: 13px;
}

.carousel.slide{
 display: flex;
 flex-direction: column-reverse;
 justify-content: flex-end;
 
}

.carousel-control-prev, .carousel-control-next{
    display: none;
}

.w-100{
    height: 350px;
    min-height: 300px;
}