.cad-anunciante.MuiAppBar-colorDefault{
    background:  #be376a;
    box-shadow: none;
}

.cad-anunciante .MuiButtonBase-root .MuiTab-wrapper{
    color: #fff;
}

.cad-anunciante-body .MuiBox-root {
    box-shadow: 0px 0px 5px 0px;
    
   
}

.cad-anunciante-body .btn-save{
    background: #b82d63;
    color: #fff;
    border-radius: 10px;
    width: 155px;
    cursor: pointer;
}

.cad-anunciante-body{
    margin-bottom: 50px;
}


.error{
    color:#b82d63;

}

