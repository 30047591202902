.perfil-container {
  max-width: 800px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 20px;
}

.perfil-titulo {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 20px;
  color: #be376a;
}

.perfil-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.perfil-left {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; 
}


.perfil-foto {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.foto-input {
  margin-top: 10px;
}

.perfil-center {
  flex: 2;
}

.perfil-right {
  margin-top: 30px;
  text-align: center;
}

.perfil-campo {
  margin-bottom: 15px;
}

.perfil-campo label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
  color: #555;
}

.perfil-campo p,
.perfil-campo input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.perfil-campo p {
  background-color: white;
  color: #333;
}

.disabled {
  background-color: #e0e0e0;
}

.perfil-acoes {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btn-editar,
.btn-salvar,
.btn-cancelar {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.btn-editar {
  background: #03aec0;
  color: #fff;
}

.btn-salvar {
  background: #28a745;
  color: #fff;
}

.btn-cancelar {
  background: #dc3545;
  color: #fff;
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 18px;
  font-weight: bold;
  color: #be3e70; 
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-top: 5px solid #be3e70; 
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}
.perfil-campo input:disabled,
.perfil-campo select:disabled {
  background-color: #f0f0f0; /* Fundo cinza claro */
  color: #999; /* Texto cinza */
  border: 1px solid #ccc; /* Borda cinza */
  cursor: not-allowed; /* Cursor indicando que está desabilitado */
}

.perfil-campo input,
.perfil-campo select {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.perfil-campo input[readonly],
.perfil-campo select[readonly] {
  background-color: #f9f9f9; /* Fundo cinza claro para leitura */
  color: #333; /* Texto mais escuro */
  border: 1px solid #ddd; /* Borda mais clara */
}
.perfil-campo p {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

.perfil-campo p.campo-desabilitado {
  background-color: #f0f0f0; /* Cor cinza clara */
  color: #333; /* Texto mais apagado */
  border: 1px solid #ccc; /* Borda cinza */
  padding: 8px; /* Espaçamento interno */
  border-radius: 6px; /* Bordas arredondadas */
  min-height: 36px; /* Altura mínima */
}

.input-style {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da; /* Cor da borda igual ao input */
  border-radius: 6px; /* Bordas arredondadas */
  background-color: #fff; /* Fundo branco */
  font-size: 13px;
  color: #333; /* Cor do texto */
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.input-style:focus {
  border-color: #0F62FE; /* Cor azul quando selecionado */
  box-shadow: 0px 0px 4px rgba(15, 98, 254, 0.5);
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
