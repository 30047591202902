.informacoes-body div h1{
    color: #03aec0;
    margin-bottom: 10px;
    font-size: 25px;
}
.informacoes-body div p{
    margin-bottom: 15px;
}.contato-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .telefone-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  .telefone {
    font-size: 14px;
    color: #555;
  }
  
  .whatsapp-link {
    background: none;
    border: none;
    color: #25d366;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease;
    padding: 0;
  }
  
  .whatsapp-link:hover {
    color: #1da851;
  }
  
  .telefone-nao-disponivel {
    font-size: 14px;
    color: #999;
  }
  