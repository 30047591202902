.header {
  padding-top: 30px;
  padding-bottom: 30px;
}

.menu-body {
  overflow-y: hidden;
}

.container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.contato {
  display: flex;
}

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b82d63;
  color: #fff;
  border-radius: 10px;
  width: 100px;
  padding: 10px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 20px
}

.auth-link {
  text-decoration: none;
  color: #fff
}

.auth-link:hover {
  text-decoration: none;
  color: #fff
}

.contato div {
  display: flex;
  align-items: center;
  margin: 0 20px;
  margin-bottom: 20px
}

.contato div:last-child {
  margin-right: 0;
}

.menu {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.menu nav {
  display: flex;
  width: 100%;
  justify-content: inherit;
  align-items: center;
}

.menu nav a {
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
  color: #2e2e2e;
  padding: 0 10px;
  margin: 0 10px;
  line-height: 2rem;
}

.menu nav a:hover {
  box-shadow: 0 3px 0 0 #b82d63;
}

.menu nav h2 {
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
  color: #2e2e2e;
  padding: 0 10px;
  margin: 0 10px;
  line-height: 2rem;
}

.menu button {
  padding: 10px 40px;
  background-color: #b82d63;
  color: #fff;
  font-size: 1rem;
  border: #b82d63;
  border-radius: 10px;
  font-weight: 700;
  margin-left: 15px;
}

.menu button:hover {
  padding: 10px 40px;
  background-color: #881038;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 10px;
}

.menu-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
}

.contato span {
  font-size: 18px;
  margin: 5px;
}

.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dropdowncontent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 10px;
  z-index: 1;
  margin-top: 30px;
}

.dropdowncontent .linkDropdown {
  margin: 10px 0;
  text-align: center;
}

.dropdowncontent:hover .linkDropdown {
  margin: 10px 0;
}

.dropdown:hover .dropdowncontent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdowneventos {
  border-top: 1px solid #881038;
  padding-top: 15px !important;
  white-space: nowrap;
}

.header-mobile {
  display: none;
}

.my-event {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #b82d63; */
  color: #fff;
  /* border-radius: 10px; */
  /* width: 155px;
  padding: 10px; */
  cursor: pointer;
  margin-right: 10px;
}

/* .my-event .my-event__label {
  margin-bottom: 0;
  font-weight: 700;
  color: 
} */

.dropdown.login {
  background: #b82d63;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  width: 155px;
  cursor: pointer;
}

.dropdown.login:hover {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdown.login h2 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}

.dropdown.login .dropdowncontent {
  background: #b82d63;
  color: #fff;
  margin-top: 25px;
  width: 134px;
  padding: 12px 0;
}

.dropdown.login .dropdowncontent a {
  color: #fff;
  width: 100%;
  padding: 5px 0;
}

.dropdown.login .dropdowncontent a:hover {
  color: #b82d63;
  text-decoration: none;
  background: #fff;
  font-weight: 700;
}

.redes-sociais img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  cursor: pointer;
}

.redes-sociais img:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 1080px) {
  .header-desktop {
    display: none;
  }

  .header-mobile {
    display: flex;
    justify-content: space-between;
  }

  .btnMenu {
    display: block;
    background-image: url(../../Assets/menu.png);
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-size: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .btnMenuClose,
  .btnMenuOpen {
    margin: 20px;
  }

  .menu-container {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 200ms linear;
  }

  .dropdown-mobile {
    display: none;
    flex-direction: column;
    margin-left: -30px;
    margin-bottom: -17px;
    margin-top: 10px;
  }

  .dropdown-mobile-last {
    display: none;
    flex-direction: column;
    margin-left: -30px;
    margin-bottom: -17px;
    margin-top: 10px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .logo {
    margin-left: 20px;
    margin-top: 20px;
  }

  .navbar-close {
    background-color: #fcfcfc;
    width: 80%;
    max-width: 400px;
    display: flex;
    justify-content: flex-end;
  }

  .menu-container ul {
    width: 80%;
    background-color: #fcfcfc;
    height: 100%;
    max-width: 400px;
    z-index: 2;
    overflow-y: scroll;
    padding-bottom: 200px;
  }

  li {
    text-align: left;
    list-style: none;
  }

  .menu-container ul li a {
    text-decoration: none;
    color: #ab4777;
  }

  .menu-container ul li {
    padding: 15px;
    padding-left: 20px;
    font-size: 1.3rem;
    font-weight: 700;
    color: #ab4777;
  }

  .menu-container ul li:nth-child(odd) {
    border-left: 10px solid #0792a1;
    border-bottom: 2px solid #0792a1;
  }

  .menu-container ul li:nth-child(odd):hover {
    border-left: 20px solid #0792a1;
    border-bottom: 2px solid #0792a1;
  }

  .menu-container ul li:nth-child(even) {
    border-left: 10px solid #ab4777;
    border-bottom: 2px solid #ab4777;
  }

  .menu-container ul li:nth-child(even):hover {
    border-left: 20px solid #ab4777;
    border-bottom: 2px solid #ab4777;
  }

  .menu-container ul li:hover {
    font-size: 1.5rem;
    font-weight: 700;
    color: #0792a1;
  }

  .menu-container ul li:hover a {
    font-size: 1.5rem;
    font-weight: 700;
    color: #0792a1;
  }

  .header-esquerda {
    display: flex;
    flex-direction: column;
  }

  .menu-contato-responsive {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 20px;
  }

  .menu-contato-responsive div {
    display: flex;
    padding: 3px;
  }

  .menu_open {
    transform: translate(0);
  }

  .menu-header {
    display: flex;
    justify-content: space-around;
    width: 80%;
    background-color: #fff;
    padding-bottom: 20px;
    z-index: 2;
    max-width: 400px;
  }

  .menu-header .logo {
    height: 80px;
  }

  .menu-header .logo img {
    width: auto;
    height: 100%;
  }

  .footer-menu {
    background-color: #fff;
    width: 80%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-top: 2px solid #ab4777;
    padding: 5px;
    z-index: 2;
    max-width: 400px;
  }

  .footer-menu div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-menu div span {
    font-size: 12px;
  }

  .overlay {
    background-color: rgba(88, 88, 88, 0.651);
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 1;
  }

  .menu-container ul .dropdown-mobile li {
    border-left: 20px solid #ab4777;
    border-bottom: 2px solid #ab4777;
  }

  .menu-container ul .dropdown-mobile-last div {
    border-left: 20px solid #ab4777;
    border-bottom: 2px solid #ab4777;
    padding: 15px;
    padding-left: 20px;
    font-size: 1.3rem;
    font-weight: 700;
    color: #ab4777;
  }

  .menu-container ul .dropdown-mobile-last div a,
  .menu-container ul .dropdown-mobile div a {
    color: #ab4777;
    text-decoration: none;
  }

  .menu-container ul .dropdown-mobile div {
    border-left: 20px solid #0792a1;
    border-bottom: 2px solid #0792a1;
    padding: 15px;
    padding-left: 20px;
    font-size: 1.3rem;
    font-weight: 700;
    color: #ab4777;
  }

  .menu-container ul .dropdown-mobile div:hover {
    border-left: 30px solid #0792a1;
  }

  .menu-container ul .dropdown-mobile div:hover a {
    color: #0792a1;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .menu-container ul .dropdown-mobile-last div:hover {
    border-left: 30px solid #ab4777;
  }

  .menu-container ul .dropdown-mobile-last div:hover a {
    color: #0792a1;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .dropdown_open {
    display: flex;
  }
}
