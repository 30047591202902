.supplier-card {
  width: 100%;
  min-width: 140px;
  height: 240px; /* Tamanho fixo */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background-color: #fff;
  overflow-y: auto;
}

.supplier-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.supplier-card__header svg {
  cursor: pointer;
}

.supplier-card__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.supplier-card__icon img {
  width: 66px;
  height: 66px;
  border: 2px solid #ddd;
  border-radius: 50%;
}

.supplier-card__content {
  flex: 1; /* Ocupa o espaço restante no card */ 
  padding: 5px;
  text-align: center;
  color: #be3e70;
}

.supplier-card__content p {
  font-size: 14px;
  font-weight: 700;
  margin: 5px 0;
}

.supplier-card__label {
  margin-top: 5px; /* Espaço entre o conteúdo e a label */
  padding: 4px 8px;
  background-color: #f0f8ff;
  border-radius: 12px;
  text-align: center;
}

.supplier-card__label p {
  font-size: 10px;
  color: #333;
  font-weight: 500;
}
